<a
  class="overlay-sidebar-header"
  [class.overlay-sidebar-header-open]="openSide"
  (click)="closeOverlay()"
></a>
<div class="responsive-btn">
  <a (click)="toggleSidebar()">
    <i class="fa fa-bars text-white" aria-hidden="true"></i>
  </a>
</div>
<div class="navbar m-l-auto" id="togglebtn" [class.openSidebar]="openSide">
  <div class="responsive-btn">
    <a (click)="toggleSidebar()">
      <h5>back</h5>
    </a>
  </div>
  <ul class="main-menu">
    <li>
        <a href="#">Home</a>
    </li>
    <li>
        <a href="#about">About</a>
    </li>
    <li>
        <a href="#features">Services</a>
    </li>
    <li>
        <a href="#portfolio">Portfolio</a>
    </li>
  </ul>
</div>
