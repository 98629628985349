import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgxSliderModule } from 'ngx-slider-v2';
import { CarouselModule } from "ngx-owl-carousel-o";
import { SharedModule } from "../shared/shared.module";
import { AgencyBlogComponent } from "./agency/agency-blog/agency-blog.component";
import { AgencyContentComponent } from "./agency/agency-content/agency-content.component";
import { AgencyCopyrightComponent } from "./agency/agency-copyright/agency-copyright.component";
import { AgencyCounterComponent } from "./agency/agency-counter/agency-counter.component";
import { AgencyFooterComponent } from "./agency/agency-footer/agency-footer.component";
import { AgencyHeaderComponent } from "./agency/agency-header/agency-header.component";
import { AgencyNavComponent } from "./agency/agency-nav/agency-nav.component";
import { AgencyPricingComponent } from "./agency/agency-pricing/agency-pricing.component";
import { AgencyServicesComponent } from "./agency/agency-services/agency-services.component";
import { AgencySpeakerComponent } from "./agency/agency-speaker/agency-speaker.component";
import { AgencyTestimonialComponent } from "./agency/agency-testimonial/agency-testimonial.component";
import { AgencyVideoComponent } from "./agency/agency-video/agency-video.component";
// Agency Layout
import { AgencyComponent } from "./agency/agency.component";
import { LayoutsRoutingModule } from "./layouts-routing.module";


@NgModule({
  declarations: [
    AgencyComponent,
    AgencyNavComponent,
    AgencyHeaderComponent,
    AgencyContentComponent,
    AgencyServicesComponent,
    AgencyVideoComponent,
    AgencyCounterComponent,
    AgencyPricingComponent,
    AgencySpeakerComponent,
    AgencyTestimonialComponent,
    AgencyBlogComponent,
    AgencyFooterComponent,
    AgencyCopyrightComponent,
  ],

  imports: [
    CommonModule,
    LayoutsRoutingModule,
    CarouselModule,
    NgbModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSliderModule,
  ],
})
export class LayoutsModule {}
