import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AgencyComponent } from "./layouts/agency/agency.component";
export const routes: Routes = [
  {
    path: "",
    component: AgencyComponent,
    data: { title: "SyntylX | Web Development Agency" },
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      anchorScrolling: "enabled",
      scrollPositionRestoration: "enabled",
      initialNavigation: "enabledNonBlocking",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
