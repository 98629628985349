<!--footer start-->
<footer class="agency footer2 p-b-0">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12 set-first">
                <div class="logo-sec">
                    <div class="footer-title mobile-title">
                        <h3 class="text-white">About Us</h3>
                    </div>
                    <div class="footer-contant">
                        <img src="assets/images/logo/1.png" alt="" class="img-fluid footer-logo">
                        <div class="footer-para">
                            <h6 class="text-white para-address">SyntylX</h6>
                            <h6 class="text-white para-address">324 Madison Street New york City, </h6>
                            <h6 class="text-white para-address">NY 10001.</h6>
                        </div>
                        <ul class="d-d-flex footer-social social">
                            <li class="footer-social-list">
                                <a href="#"><i class="fa fa-facebook" aria-hidden="true"></i></a>
                            </li>
                            <li class="footer-social-list">
                                <a href="#"><i class="fa fa-twitter" aria-hidden="true"></i></a>
                            </li>
                            <li class="footer-social-list">
                                <a href="#"><i class="fa fa-google" aria-hidden="true"></i></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-2 col-md-3 col-sm-12">
                <div class="footer-title mobile-title">
                    <h3 class="text-white">services</h3>
                </div>
                <div class="footer-contant">
                    <h5 class="footer-headings">services</h5>
                    <div>
                        <ul class="footer-lists op-text">
                            <li class="">
                                <a href="#">Portfolio Website</a>
                            </li>
                            <li class="">
                                <a href="#">Set up an account</a>
                            </li>
                            <li class="">
                                <a href="#">Invoice Creation</a>
                            </li>
                            <li class="">
                                <a href="#">Get Our Specials</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-2 col-md-3 col-sm-12">
                <div class="footer-title mobile-title">
                    <h3 class="text-white">brand</h3>
                </div>
                <div class="footer-contant">
                    <h5 class="footer-headings">brand</h5>
                    <div>
                        <ul class="footer-lists op-text">
                            <li class="">
                                <a href="#">Chat24</a>
                            </li>
                            <li class="">
                                <a href="#">Zoom</a>
                            </li>
                            <li class="">
                                <a href="#">Alpha Payment</a>
                            </li>
                            <li class="">
                                <a href="#">Upwork</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-2 col-md-3 col-sm-12">
                <div class="footer-title mobile-title">
                    <h3 class="text-white">Support</h3>
                </div>
                <div class="footer-contant">
                    <h5 class="footer-headings">Support</h5>
                    <div>
                        <ul class="footer-lists op-text">
                            <li class="">
                                <a href="#">For Freelancers</a>
                            </li>
                            <li class="">
                                <a href="#">For Companies</a>
                            </li>
                            <li class="">
                                <a href="#">For Business</a>
                            </li>
                            <li class="">
                                <a href="#">General Help</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-12 col-sm-12 set-last">
                <div class="footer-title mobile-title">
                    <h3 class="text-white">Post Tags</h3>
                </div>
                <div class="footer-contant">
                    <h5 class="footer-headings">Post Tags</h5>
                    <div class="link-btns">
                        <ul>
                            <li class="buttons">
                                <a href="#">app</a>
                            </li>
                            <li class="buttons">
                                <a href="#">business</a>
                            </li>
                            <li class="buttons">
                                <a href="#">corporation</a>
                            </li>
                            <li class="buttons">
                                <a href="#">creative</a>
                            </li>
                            <li class="buttons">
                                <a href="#">design</a>
                            </li>
                            <li class="buttons">
                                <a href="#">fashion</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <!--footer end-->
        <div class="row">
            <div class="col-12">
                <div class="brand-container">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="link-horizontal">
                                <ul>
                                    <li>
                                        <a href="#" class="center-content">
                                            <img src="assets/images/agency/brand/1.png" alt="" class="img-fluid">
                                        </a>
                                    </li>
                                    <li class="borders-right p-0"></li>
                                    <li>
                                        <a href="#" class="center-content">
                                            <img src="assets/images/agency/brand/2.png" alt="" class="img-fluid">
                                        </a>
                                    </li>
                                    <li class="borders-right p-0"></li>
                                    <li>
                                        <a href="#" class="center-content">
                                            <img src="assets/images/agency/brand/3.png" alt="" class="img-fluid">
                                        </a>
                                    </li>
                                    <li class="borders-right p-0"></li>
                                    <li>
                                        <a href="#" class="center-content">
                                            <img src="assets/images/agency/brand/4.png" alt="" class="img-fluid">
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="list float-right">
                                <ul>
                                    <li>
                                        <a href="#">
                                            <img src="assets/images/agency/brand/paytm-1.png" alt=""
                                                 class="img-fluid">
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <img src="assets/images/agency/brand/paytm-2.png" alt=""
                                                 class="img-fluid">
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <img src="assets/images/agency/brand/paytm-3.png" alt=""
                                                 class="img-fluid">
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <img src="assets/images/agency/brand/paytm-4.png" alt=""
                                                 class="img-fluid">
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>
<!--footer end-->
<app-tap-to-top></app-tap-to-top>

