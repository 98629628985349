<!--header start-->
<section class="agency header" id="header">
  <div
    class="agency bg"
    [ngStyle]="{
      'background-image': 'url(assets/images/agency/slider/slider.png)'
    }"
  >
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <div class="center-text">
            <div>
              <div class="header-text">
                <h1 class="text-uppercase">
                  <span class="bold-text">Syntyl</span> X
                </h1>
              </div>
              <div class="header-sub-text">
                <p>
                  At SyntylX, our mission is to provide top-notch website
                  maintenance, security, and development services that ensure
                  your online presence is secure, efficient, and tailored to
                  your needs. We are dedicated to delivering exceptional service
                  and support to help your business thrive online.
                </p>
              </div>
              <div class="link-horizontal">
                <ul>
                  <li>
                    <a class="btn btn-default btn-gradient text-white"
                      >request a quote</a
                    >
                  </li>
                  <li>
                    <a class="btn btn-default btn-gradient text-white active"
                      >start now</a
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="set-abs abs-horizon-center girl-boy">
    <img
      src="assets/images/agency/slider/img.png"
      alt=""
      class="img-fluid"
      aTilt
      data-tilt
      data-tilt-max="5"
      data-tilt-speed="400"
      data-tilt-perspective="500"
    />
    <div class="plus-container plus-container6">
      <div class="plus white"></div>
    </div>
    <div class="plus-container plus-container7">
      <div class="plus white"></div>
    </div>
    <div class="plus-container plus-container8">
      <div class="plus white"></div>
    </div>
    <div class="circle-container">
      <div class="gradient-circle-big"></div>
    </div>
    <div class="circle-container small">
      <div class="gradient-circle-small"></div>
    </div>
  </div>
  <div class="plus-container">
    <div class="plus"></div>
  </div>
  <div class="plus-container plus-container2">
    <div class="plus plus-medium"></div>
  </div>
  <div class="plus-container plus-container3">
    <div class="plus plus-small"></div>
  </div>
  <div class="plus-container plus-container4">
    <div class="plus plus-small"></div>
  </div>
  <div class="plus-container plus-container5">
    <div class="plus plus-medium"></div>
  </div>
</section>
<!--header end-->
