<!--service section start-->
<section
  class="agency format service-bg"
  id="features"
  [ngStyle]="{
    'background-image':
      'url(assets/images/agency/service/services-backgrondpng.png)'
  }"
>
  <div class="container">
    <div class="row">
      <div class="col-md-4 offset-lg-1">
        <div class="center-text">
          <div class="format-container">
            <h6 class="borders-before text-uppercase">
              <span class="">Our services</span>
            </h6>
            <div class="format-head-text">
              <h2 class="about-font-header">
                Services We <span class="block-span"> Provide</span>
              </h2>
            </div>
            <div class="format-sub-text">
              <p class="about-para">
                We specialize in website maintenance, security, and development.
                Our goal is to keep your online presence secure and running
                smoothly. Connect with us for expert support and innovative
                solutions.
              </p>
            </div>
            <a href="#" class="btn btn-default btn-gradient text-white"
              >view more</a
            >
          </div>
        </div>
      </div>
      <div class="col-lg-7 col-md-8">
        <div class="row">
          <div class="col-sm-4 center-content unset-height">
            <ul
              class="icon-collection"
              [@fadeInRight]="{
                value: fadeInRight,
                params: { timing: 3, delay: 0 }
              }"
            >
              <li class="about-icon">
                <a href="#" class="center-content"
                  ><img src="assets/images/agency/service/1.png" alt="" />
                  <h5>Website Design</h5>
                </a>
              </li>
            </ul>
          </div>
          <div class="col-sm-4 center-content unset-height">
            <ul
              class="icon-collection"
              [@fadeInRight]="{
                value: fadeInRight,
                params: { timing: 3, delay: 2 }
              }"
            >
              <li class="about-icon">
                <a href="#" class="center-content"
                  ><img src="assets/images/agency/service/2.png" alt="" />
                  <h5>Website Development</h5>
                </a>
              </li>
              <li class="about-icon">
                <a href="#" class="center-content"
                  ><img src="assets/images/agency/service/3.png" alt="" />
                  <h5>Graphic Design</h5>
                </a>
              </li>
            </ul>
          </div>
          <div class="col-sm-4 center-content unset-height">
            <ul
              class="icon-collection"
              [@fadeInRight]="{
                value: fadeInRight,
                params: { timing: 3, delay: 3 }
              }"
            >
              <li class="about-icon">
                <a href="#" class="center-content"
                  ><img src="assets/images/agency/service/4.png" alt="" />
                  <h5>Social Media Management</h5>
                </a>
              </li>
              <li class="about-icon">
                <a href="#" class="center-content"
                  ><img src="assets/images/agency/service/5.png" alt="" />
                  <h5>Website Maintenance</h5>
                </a>
              </li>
              <li class="about-icon">
                <a href="#" class="center-content"
                  ><img src="assets/images/agency/service/6.png" alt="" />
                  <h5>Security Solutions</h5>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--service section end-->
