<!--agency content start-->
<section class="agency agency-content about-bg" id="about">
    <h3 class="text-as-shadow">About <br/> us</h3>
    <div class="container">
        <div class="row">
            <div class="col-xl-9 offset-xl-3 col-md-9 offset-md-3">
                <div class="agency-header-center-container">
                    <div class="borders"></div>
                    <div class="agency-head">
                        <h3 class="agency-head-text">We Are Here <span class="block-span">For Your Idea</span>
                        </h3>
                    </div>
                </div>

                <p class="agency-para">Our mission is to provide top-notch website maintenance, security, and development services that ensure your online presence is secure, efficient, and tailored to your needs. We are dedicated to delivering exceptional service and support to help your business thrive online.</p>
                <a href="#" class=" btn btn-default btn-gradient text-white">view more</a>
            </div>
        </div>
    </div>
    <div class="side-img  set-abs left-0 top-0" [@fadeInLeft]="fadeInLeft">
        <div class="plus-container plus-container9">
            <div class="plus white">
            </div>
        </div>
        <div class="plus-container plus-container10">
            <div class="plus plus-medium white">
            </div>
        </div>
        <div class="plus-container plus-container11">
            <div class="plus plus plus-small white">
            </div>
        </div>
        <img src="assets/images/agency/about-us/image.png" alt="" class="img-fluid about-img">
    </div>
    <img src="assets/images/agency/agency-abs-img.png" alt="" class="img-fluid blue-img">
</section>
<!--agency content end-->
